@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Segoe-UI','Segoe-UI-Bold';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'Segoe-UI';
  src: local('Segoe-UI'), url(../public/assets/Fonts/Segoe-font/Segoe-UI.ttf) format('truetype');
}

@font-face {
  font-family: 'Segoe-UI-Bold';
  src: local('Segoe-UI'), url(../public/assets/Fonts/Segoe-font/Segoe-UI-Bold.ttf) format('truetype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-container {
  background-color: #FFFFFF;
  border-radius: 36px;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.24);
  width: 100%;
  height: fit-content;
  padding-bottom: 10px;
}

.login-bg-img {
  background-image: url(../public/assets/login-background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.ant-table-tbody {
  && .ant-table-row {
    && td:first-child {
      border-top-left-radius: theme('borderRadius.lg');
      border-bottom-left-radius: theme('borderRadius.lg');
    }

    && td:last-child {
      border-top-right-radius: theme('borderRadius.lg');
      border-bottom-right-radius: theme('borderRadius.lg');
    }
  }
}

.ant-select-selection-search-input {
  color: theme('colors.black') !important;
}

.ant-table-pagination {
  && .ant-pagination-item {
    & a {
      color: #2D3D5A;
    }
    background-color: #F0F3FA;
    border-radius: theme('borderRadius.full');
  }

  && .ant-pagination-item-active {
    & a {
      color: theme('colors.white');
    }
    background-color: #2D3D5A;
    border-color: #2D3D5A;
    border-radius: theme('borderRadius.full');
  }
}

.ant-upload-wrapper {
  && .ant-upload, .ant-upload-btn {
    padding: 0;
  }
}

.ant-upload-list {
  display: flex;
}

.ant-upload-list-item-container {
  margin-right: 15px;
}

.ant-upload-list-item {
  width: 200px;
}

.ant-checkbox, .ant-checkbox-inner {
  border: solid 1px #2D3D5A;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2D3D5A;
  border-color: #2D3D5A;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: white;
}