:global(.ant-btn-primary) {
    background-color: #2D3D5A;

    &:hover {
        background-color: #2D3D5A !important;
    }
}

:global(.ant-switch) {
    background-color: theme('colors.gray.400');
}

:global(.ant-switch.ant-switch-checked) {
    background-color: #2D3D5A;

    &:hover {
        background-color: #2D3D5A !important;
    }
}

:global(.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell) {
    font-weight: bold;
    font-size: 16px;
    font-family: 'Segoe-UI-Bold';
    text-align: center;
    padding: 10px;
}

:global(.ant-table-cell-row-hover){
    background-color: unset !important;
}